@use "base";

$medium: 768px;
$large: 1400px;

/* === MEDIUM SCREENS === */
@media screen and (min-width: $medium) {
  /* === HEADER === */
  .hero {
    max-width: 1200px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding: 8rem 2rem;
  }

  .hero__text-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .hero__title {
      font-size: 4rem;
    }

    .hero__info {
      font-size: 1.5rem;
    }
  }
  
  .hero__img {
    width: 30%;
  }

  .hero__info {
    max-width: 80%;
  }

  /* === MAIN === */
  .main {
    padding-top: 4rem;
  }

  form {
    padding-bottom: 4rem;
    max-width: 800px;
  }

  .form__subject-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    input[name="subject"] {
      width: 55%;
    }
  
    .form_level {
      margin-right: 1rem;
    }
  }

  .results {
    width: 100vw;
  }

  .results__render {
    max-width: 700px;
    padding: 1.5rem 2rem 2rem;
  }
}

/* === LARGE SCREENS === */
@media screen and (min-width: $large) {
  /* === HEADER === */
  .hero__img {
    width: 35%;
  }
  /* === MAIN === */
  .main-container {
    max-width: 1800px;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
  }

  form {
    padding-bottom: 4rem;
    max-width: 800px;
  }

  .loading {
    width: 800px;
  }

  /* === RESULTS === */
  .results {
    max-width: 800px;
    background-color: base.$tertiary;
  }

  .results__render {
    width: auto;
  }
}
