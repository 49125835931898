@use "base";

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: base.$tertiary;
}

/* === MAIN === */
.main {
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.5rem;
  background-color: base.$tertiary;

  h2 {
    font-size: 2rem;
  }
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* === FORM === */
.main__form {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.5rem;

  * {
    font-family: "Karla", sans-serif;
  }
}

.form__instruction-text {
  font-weight: 600;
  font-size: 1.2rem;
  margin-right: auto;
}

.form__subject-container {
  width: 100%;
}

input[name="subject"] {
  width: 100%;
  margin-right: 2rem;
}

.form_length, .form_level {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  height: 3rem;

  select {
    margin-left: 0.5rem;
    height: 3rem;
    border-radius: 9px;
  }
}

input[type="text"] {
  margin-bottom: 15px;
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  text-indent: 10px;
  border: 1px solid #532e1c8e;
  border-radius: 9px;
  display: block;
}

textarea {
  width: 100%;
  height: 8rem;
  margin-bottom: 15px;
  font-family: "Karla", sans-serif;
  font-size: 0.9rem;
  padding: 1rem;
  border: 1px solid #532e1c8e;
  border-radius: 9px;
  display: block;
}

textarea:focus, input:focus {
  outline: none;
}

.form__button {
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 20px;
  height: 3rem;
  width: 250px;
  border: none;
  border-radius: 9px;
  cursor: pointer;
  color: base.$light;
  background-color: base.$primary;
}

.form__button:active {
  box-shadow: inset 5px 5px 10px -3px base.$secondary;
}

/* === RESULTS === */
.results {
  max-width: 100%;
  min-height: 400px;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: base.$tertiary;
}

.results__render {
  width: 90vw;
  border-radius: 9px;
  padding: 0 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: base.$light;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/* === RESULTS ANIMATIONS === */
/* appear is when the component mounts */
.animate-results-appear {
  opacity: 0;
  transform: scale(0.2);
}
.animate-results-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}
