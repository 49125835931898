@use "base";

/* === FOOTER === */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  width: 100%;
  color: base.$light;
  background-color: rgb(18, 21, 18);
}

.footer__link {
  color: base.$light;
}

.footer--link:hover {
  text-decoration: none;
  font-weight: bold;
}
