// $primary: #c5a880;
// $secondary: #532e1c;
// $light: #f8fbff;
// $dark: #282a37;

$primary: #1D5C63;
$secondary: #417D7A;
$tertiary: #EDE6DB;
$light: #f8fbff;
$dark: #153033;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Karla", sans-serif;
}