@use "base";

/* === LOADER ANIMATIONS === */
/* appear is when the component mounts */
.load-appear {
  opacity: 0;
  transform: scale(0.2);
}
.load-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}

.load-exit {
  opacity: 1;
}
.load-exit-active {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 1000ms, transform 1000ms;
}

/* === LOADER === */
.loader {
  padding: 5rem 2rem;
}

.loader__error-cont {
  max-width: 20rem;
  max-height: 350px;
  border-radius: 9px;
  margin-bottom: 3rem;
  padding: 1.5rem 0.8rem;
  text-align: center;
  line-height: 1.4rem;
  background: #FF7272;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.loader__error-cont:before {
  content: "";
  position: absolute;
  top: 95%;
  left: 50%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 26px solid #FF7272;
  border-bottom: 13px solid transparent;
}

.loader__load-animation {
  display: flex;
  justify-content: center;

  div {
    width: 1rem;
    height: 1rem;
    margin: 2rem 0.3rem;
    background: base.$secondary;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}
